const colorShadow = [
    ['#3391b5', '#1a83ab', '#0075a2', '#006992', '#005271'],
    ['#ffbf00', '#ffa04d', '#e00300', '#004d00', '#004d00'],
    ['#00ffaa', '#4dff00', '#00e04d', '#000033', '#000033'],
    ['#df0f0f', '#d20000', '#ac3030', '#032020', '#032020'],
    ['#ffbd00', '#ffac00', '#ff0a1a', '#003000', '#003000']

]
export const shadowColor = (shadowIndex: number = 1, colorIndex: number = 1) => {
    shadowIndex = shadowIndex > 4 ? 4 : shadowIndex;
    colorIndex = colorIndex && Math.round(colorIndex)  > 4 ? 4 : Math.round(colorIndex);
    return colorShadow[shadowIndex][colorIndex];
};


export const interests = [
    {
        title: 'Realistic',
        value: 1
    },
    {
        title: 'Investigative',
        value: 4
    },
    {
        title: 'Artistic',
        value: 3
    },
    {
        title: 'Social',
        value: 1
    },
    {
        title: 'Enterprising',
        value: 1
    },
    {
        title: 'Conventional',
        value: 1
    },
    {
        title: 'First Interest High-Point',
        value: 2
    },
]


export const workValues = [
    {
        title: 'Achievement',
        value: 1
    },
    {
        title: 'Working Conditions',
        value: 1
    },
    {
        title: 'Recognition',
        value: 1
    },
    {
        title: 'Relationships',
        value: 1
    }
]

export const workStyles = [
    {
        title: 'Achievement/Effort',
        value: 1
    },
    {
        title: 'Persistence',
        value: 1
    },
    {
        title: 'Initiative',
        value: 1
    },
    {
        title: 'Leadership',
        value: 1
    },
    {
        title: 'Cooperation',
        value: 1
    },
    {
        title: 'Concern for Others',
        value: 1
    },
    {
        title: 'Social Orientationt',
        value: 1
    }
]

export const workContaxts = [
    {
        title: 'Public Speaking',
        value: 1
    },
    {
        title: 'Telephone',
        value: 1
    },
    {
        title: 'Electronic Mail',
        value: 1
    },
    {
        title: 'Letters and Memos',
        value: 1
    },
]

export const workActivites = [
    {
        title: 'Public Speaking',
        value: 1
    },
    {
        title: 'Telephone',
        value: 1
    },
    {
        title: 'Electronic Mail',
        value: 1
    },
    {
        title: 'Letters and Memos',
        value: 1
    },
]


export const steps = [
    {
        title: 'Catalog Information',
    },
    {
        title: 'Role Information',
    },
    {
        title: 'Requirements',
    },
    {
        title: 'Capabilities',
    },
    {
        title: 'Values',
    },
    {
        title: 'Summary',
    },
];

export const functionLevel = [
    { key: 'L1', value: 'L1', label: 'L1' },
    { key: 'L2', value: 'L2', label: 'L2' },
    { key: 'L3', value: 'L3', label: 'L3' },
    { key: 'L4', value: 'L4', label: 'L4' },
    { key: 'L5', value: 'L5', label: 'L5' }
]
export const noRecordMsg = 'No Rows To Show.'

export const langaugeList = [
    {
        title: 'English',
        value: 'English'
    },
    {
        title: 'Finnish',
        value: 'Finnish'
    },
    {
        title: 'French',
        value: 'French'
    },
    {
        title: 'German',
        value: 'German'
    },
] 
export const statusList = [
    {
        title: 'Active',
        value: 'Active'
    },
    {
        title: 'Inactive',
        value: 'Inactive'
    }
]

export const questionList = [
    {
        title: 'Text',
        value: 'Text'
    },
    {
        title: 'Number',
        value: 'Number'
    },
    {
        title: 'SelectOneQuestion',
        value: 'SelectOneQuestion'
    },
    {
        title: 'SelectMultipleQuestion',
        value: 'SelectMultipleQuestion'
    },
    {
        title: 'Boolean',
        value: 'Boolean'
    }
]

export const questionSizeList = [
    {
        title: 'Small (255 or less)',
        value: 'Small (255 or less)'
    },
    {
        title: 'Rich Text (up to 32k)',
        value: 'Rich Text (up to 32k)'
    }
]

export const correctBoleanList = [
    {
        title: 'true',
        value: 'true'
    },
    {
        title: 'false',
        value: 'false'
    }
]


export const stepQuestions = [
    {
        title: 'Add Question set',
    },
    {
        title: 'Add Question',
    },
    {
        title: 'Add Answer',
    }
];