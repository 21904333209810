import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Select, Space, Input } from "antd";
import "./Jd-Answer-Create.css";
import { getQuestionList } from "../../../../utilities/http-services/caq-management.service";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const iconCss: React.CSSProperties = { fontSize: "1.3rem", color: "#ff4d4f" };
const hideLabelCss: React.CSSProperties = { paddingTop: "2rem" };
export interface IProps {
  data?: any;
  onClose?: () => void;
  onFinish?: any;
  onClear?: any;
  disabledForm?: boolean;
  fromPage?: any;
  questionData?: any;
  rowData?: any;
}
const JdAnswerCreate: React.FC<IProps> = (pageProps) => {
  const [form] = Form.useForm();
  const [questionList, setQuestionOptions] = useState([]);
  const onFinish = (values: any) => {
    pageProps.onFinish(values);
  };
  // const onClear = () => {
  //   //reset form
  //   form.resetFields();
  //   if (pageProps.onClear) pageProps.onClear();
  //   if (pageProps?.questionData?._id) {
  //     form.setFieldsValue({ question: pageProps?.questionData?._id });
  //   }
  // };
  useEffect(() => {
    getQuestionList().then((res: any) => {
      console.log(res);
      setQuestionOptions(res);
    });
  }, []);
  useEffect(() => {
    if (pageProps.data && Object.keys(pageProps.data)) {
      setFormValue(pageProps.data);
    } else if (pageProps?.questionData?._id) {
      form.setFieldsValue({ question: pageProps?.questionData?._id });
      form.setFieldsValue({
        answers: [
          {
            name: "",
            answer: "",
            score: "",
            order: (pageProps?.rowData?.length+1) || 1,
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageProps?.data, pageProps?.questionData?._id]);

  const setFormValue = (data: any) => {
    form.setFieldsValue({ name: data.name });
    form.setFieldsValue({ answer: data.answer });
    form.setFieldsValue({ score: data.score });
    form.setFieldsValue({ order: data.order || (pageProps?.rowData?.length+1) || 1 });
    form.setFieldsValue({ question: data.question });
    form.setFieldsValue({
      answers: [
        {
          name: data.name,
          answer: data.answer,
          score: data.score,
          order: data.order || (pageProps?.rowData?.length+1) || 1 ,
        },
      ],
    });
  };
  const pageWithFrom = () => {
    return (
      <>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          hideRequiredMark
          autoComplete="off"
        >
          {pageWithoutForm()}
          <Row>
            <Col span={24}>
              <Space>
                <Button onClick={pageProps.onClose}>Cancel</Button>
                {/* {pageProps?.data ? (
                ) : (
                  <Button onClick={onClear}>Clear</Button>
                )} */}
                <Button type="primary" htmlType="submit">
                  Submit{" "}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </>
    );
  };
  const pageWithoutForm = () => {
    return (
      <>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Question"
              name="question"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: "Question is required" }]}
            >
              <Select
                placeholder="--None--"
                options={questionList}
                disabled={pageProps?.questionData?._id ? true : false}
              />
            </Form.Item>
          </Col>
          <Form.List name="answers">
            {(fields, { add, remove }) => (
              <React.Fragment>
                {!pageProps?.data && (
                  <Col span={12}>
                    <Form.Item style={hideLabelCss}>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add()
                        }}
                        // style={{ width: '80%' }}
                        icon={<PlusOutlined />}
                      >
                        Add Answer
                      </Button>
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <div key="antDesign" className="addMoreGrid">
                    <div key="jdcAddMoreAnswer" className="addMore">
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            label="Answer name"
                            name={[name, "name"]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Answer name is required",
                              },
                            ]}
                          >
                            <Input placeholder="Answer name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label="Answer"
                            name={[name, "answer"]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Answer is required",
                              },
                            ]}
                          >
                            <Input placeholder="Answer" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label="Score"
                            name={[name, "score"]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ maxWidth: 150 }}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Score is required",
                              },
                              {
                                validator: (_, value) => {
                                  if (value && (isNaN(value) || value < 0)) {
                                    return Promise.reject(
                                      "Score must be greater than or equal to 0"
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input placeholder="Score" />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            label="Order"
                            name={[name, "order"]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ maxWidth: 150 }}
                            rules={[
                              {
                                validator: (_, value) => {
                                  // Check that order is a number greater than 0
                                  if (value && (isNaN(value) || value <= 0)) {
                                    return Promise.reject(
                                      "Order must be greater than 0"
                                    );
                                  }

                                  if (String(value).includes(".")) {
                                    return Promise.reject(
                                      "Please enter a natural number."
                                    );
                                  }

                                  // if (value > fields?.length) {
                                  //   return Promise.reject(
                                  //     `Order must be less than or equal to ${fields?.length}`
                                  //   );
                                  // }

                                  // Check for uniqueness: Ensure no other entry has the same order value
                                  const allOrders = form
                                    .getFieldsValue()
                                    ?.answers?.filter(
                                      (each: any) => each?.order
                                    )
                                    ?.map((each: any) => each?.order);
                                  const orderCount = allOrders?.filter(
                                    (order: any) => +order === +value
                                  );

                                  if (orderCount?.length > 1) {
                                    return Promise.reject(
                                      "Order value must be unique"
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input placeholder="Order" maxLength={4}/>
                          </Form.Item>

                          {/* <Form.Item
                                {...restField}
                                name={[name, 'is_correct']}
                                valuePropName="checked"
                            >
                                <Checkbox>Is Correct</Checkbox>
                            </Form.Item> */}
                          {!pageProps?.data && form?.getFieldsValue()?.answers?.length > 1 && (
                            <Form.Item
                              label="Remove"
                              className="answerRemoveLabel"
                              labelCol={{ span: 24 }}
                              wrapperCol={{ span: 24 }}
                              style={{ maxWidth: 40 }}
                            >
                              <MinusCircleOutlined
                                style={iconCss}
                                title="Remove"
                                onClick={() => remove(name)}
                              />
                            </Form.Item>
                          )}
                        </Space>
                      ))}
                    </div>
                  </div>
                </Col>
              </React.Fragment>
            )}
          </Form.List>
        </Row>
      </>
    );
  };

  return (
    <div className="add-answer-content">
      {pageProps.fromPage === "addQuest" ? pageWithoutForm() : pageWithFrom()}
    </div>
  );
};

export default JdAnswerCreate;
