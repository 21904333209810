import React, { useCallback } from "react";
import type { MenuProps } from "antd";
import { Layout, Menu} from "antd";
import "./Sidebar.css";
import {
  ExportOutlined,
  UnorderedListOutlined,
  PlusSquareOutlined,
  MailOutlined,
  MonitorOutlined,
  TeamOutlined,
  FlagOutlined,
  CheckCircleOutlined,
  CommentOutlined,
  LaptopOutlined,
} from "@ant-design/icons";

import "./Sidebar.css";
import { useNavigate } from "react-router-dom";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import environment from "../../../../environments/environment";
const { Sider } = Layout;
const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const siderStyle: React.CSSProperties = {
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vh - 64px)",
    position: "inherit",
    left: 0,
    top: 0,
    bottom: 0,
  };

  const onMenuClick = useCallback((event) => {
    let { key } = event;
    navigate(`${key}`, { replace: true });
  }, []);
  const userInfo:any = user;
  const userMetaData = JSON.parse(userInfo['https://den.serviceo.me/app_metadata']);
  const menujson = [
    {
      icon: LaptopOutlined,
      label: "Dashboard",
      key: "",
    },
    {
      icon: UnorderedListOutlined, 
      label: "List JDC",
      key: "list-jd-catalog",
    },
    // {
    //   icon: FlagOutlined,
    //   label: "Milestone",
    //   key: "milestone",
    //   disabled: true
    // },
    // {
    //   icon: MonitorOutlined,
    //   label: "Analytics",
    //   key: "analytics",
    //   disabled: true
    // },
    // {
    //   icon: ExportOutlined,
    //   label: "Attendance",
    //   key: "attendance",
    //   disabled: true
    // },
    // {
    //   icon: TeamOutlined,
    //   label: "Recruitment",
    //   key: "recruitment",
    //   disabled: true
    // },
    // {
    //   icon: CommentOutlined,
    //   label: "Chat",
    //   key: "chat",
    //   disabled: true
    // },

    // {
    //   icon: CheckCircleOutlined,
    //   label: "Task",
    //   key: "task",
    //   disabled: true
    // },
    // {
    //   icon: MailOutlined,
    //   label: "Inbox",
    //   key: "inbox",
    //   disabled: true
    // },
    // {
    //   icon: MonitorOutlined,
    //   label: "UI DESIGN",
    //   key: "ui-example",
    //   disabled: false
    // },

    
  ];
  let userType:any = userMetaData['userType'] ? userMetaData['userType'].toLowerCase() : '';
  let accessType = userMetaData['accessType'] ? userMetaData['accessType'].toLowerCase() : '';
  let isAdmin = (userType === 'super-admin' || userType=== 'admin') && accessType === 'internal' ? true : false;
  const adminAccess:any = environment.jdcAccess? environment.jdcAccess: [];
  if(isAdmin || adminAccess.includes(userType['sfdcId'])){
    menujson.splice(2, 0, 
      {
        icon: PlusSquareOutlined,
        label: "Add JDC",
        key: "create-jd-catalog",
      })
  }
  if(isAdmin || userType && (userType === 'super-admin' || userType=== 'admin') && accessType==='internal'){
    menujson.splice(3, 0, 
      {
      icon: FlagOutlined,
      label: "Talent Type",
      key: "talent-type"
      },
      {
        icon: FlagOutlined,
        label: "Service Type",
        key: "service-type"
      },
      {
        icon: ExportOutlined,
        label: "Abilities",
        key: "ability"
      },
      {
        icon: MonitorOutlined,
        label: "Knowledge",
        key: "knowledge"
      },
      {
        icon: CheckCircleOutlined,
        label: "Skills",
        key: "skill"
      },
      {
        icon: UnorderedListOutlined,
        label: "Tasks",
        key: "task"
      },
      {
        icon: FlagOutlined,
        label: "Technicals",
        key: "technical"
      },
      {
        icon: MonitorOutlined,
        label: "Tool Used",
        key: "tool-used"
      },
      {
        icon: UnorderedListOutlined,
        label: "Job Titles",
        key: "job-titles"
      }, 
      // {
      //   icon: UnorderedListOutlined,
      //   label: "Add Question Set",
      //   key: "add-question-set"
      // },
      {
        icon: UnorderedListOutlined,
        label: "Question Sets",
        key: "question-sets"
      },
      // {
      //   icon: UnorderedListOutlined,
      //   label: "Questions",
      //   key: "questions"
      // },
      // {
      //   icon: UnorderedListOutlined,
      //   label: "Answers",
      //   key: "answers"
      // },
      // {
      //   icon: UnorderedListOutlined,
      //   label: "Answer Sequence",
      //   key: "answer-sequence"
      // },
      // {
      //   icon: UnorderedListOutlined,
      //   label: "Answer Sequence Item",
      //   key: "answer-sequence-item"
      // }
    
    );
  }
  const items2: MenuProps["items"] = menujson.map((item, index) => {
    return {
      key: item.key,
      disabled: false,
      label: (
        <span>
          {React.createElement(item.icon)} <br />
          <small title={item.label}>{item.label}</small>
        </span>
      ),
    };
  });
  return (
    <Sider width={70} className="site-layout-background" style={siderStyle}>
      <Menu
        mode="vertical"
        onClick={onMenuClick}
        defaultSelectedKeys={[""]}
        style={{ height: "100%", borderRight: 0 }}
        items={items2}
      />
    </Sider>
  );
}


export default Sidebar;