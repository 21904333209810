const environment = {
  envName: process.env.REACT_APP_ENV_NAME,
  production: false,
  baseUrl: process.env.REACT_APP_BASE_URL,
  serviceoBaseurl: process.env.REACT_APP_SERVICEO_BASE_URL,
  apiVersion: process.env.REACT_APP_API_VERSION,
  auth: {
      App_Name: process.env.REACT_APP_AUTH_NAME || '',
      CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID || '',
      CLIENT_DOMAIN: process.env.REACT_APP_AUTH_CLIENT_DOMAIN || '',
      REDIRECT: process.env.REACT_APP_AUTH_REDIRECT_URL || window.location.origin,
      LOGOUT_URL: process.env.REACT_APP_AUTH_REDIRECT_URL|| '',
      CACHE_LOCATION: 'localstorage',
      USE_REFRESH_TOKENS: true,
  },
  jdcAccess: process.env.REACT_APP_AUTH_ADMIN_ACCESS_ROLES,
  talentMasterUrl: process.env.REACT_APP_TALENT_MASTER_URL || ''
}

export default environment;

// const environment = {
//   envName: "staging",
//   production: false,
//   baseUrl: "http://localhost:9090/jdc",
//   serviceoBaseurl: "https://den.staging.serviceo.me/api/v1",
//   apiVersion: "api/v1",
//   auth: {
//     App_Name: "sg-mf-react-app-staging",
//     CLIENT_ID: "JJ5Denna7bqupnhE8lB2DzaN7JnTgMmh",
//     CLIENT_DOMAIN: "serviceo-staging.auth0.com",
//     REDIRECT: "https://jdc.staging.serviceo.me" || window.location.origin,
//     LOGOUT_URL: "https://jdc.staging.serviceo.me",
//     CACHE_LOCATION: "localstorage",
//     USE_REFRESH_TOKENS: true,
//   },
//   jdcAccess: ["0031a00000mVdgjAAC", "0034z00002kFs78AAC", "0031a00000mVdhIAAS"],
//   talentMasterUrl: "https://api.staging.serviceo.me/metadata/",
// };

// export default environment;
