import environment from '../../environments/environment';
import "./fetch-intercept.service";
const baseUrl = environment.baseUrl + '/api/v1';

export const getTalentTypeList = (pageSize: number = 100, pageNo: Number = 0): Promise<unknown> => {
    const aprUrl = `${baseUrl}/jd/talentType?pageSize=${pageSize}&page=${pageNo}`;
    return fetch(aprUrl)
        .then((resp) => resp.json())
        .then((data) => {
            return data;
        });
}
export const getTalentTypeById = (tId:any): Promise<unknown> => {
    const apiUrl = `${baseUrl}/jd/talentType/${tId}/detail`;
    return fetch(apiUrl)
        .then((resp) => resp.json())
        .then((data) => {
            return data;
        });
}
export const updateTalentType = (tId: string, data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/jd/talentType/` + tId;
    let postData: any = data;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data._id);

}
export const getTalentTypeAllList = (pageSize: number = 50, paneNo: Number = 0,filter={}): Promise<unknown> => {
    const listUrl = `${baseUrl}/jd/talentList?pageSize=${pageSize}&page=${paneNo}`;
    const countTalentUrl = `${baseUrl}/jd/talenttypecount`;
    const requestOptions:any = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    
    if(filter!==undefined && Object.keys(filter) && Object.keys(filter).length){
       requestOptions['body'] = JSON.stringify(filter);
    }
    const talentApiUrl: any = [fetch(listUrl,requestOptions), fetch(countTalentUrl,requestOptions) ];  

    return Promise.all(talentApiUrl).then(function (responses) {
        return Promise.all(responses.map(function (response) { return response.json() }));
    }).then(function (promiseData) {
        return promiseData;
    })

}

export const addTalentType = (data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/jd/createTalentType`;
    let postData: any = [{
        title: data.title,
        description:data.title,
        code: data.code,
        sku: data.skuIc,
        talentTypeCategory:data.talentTypeCategory,
        OwnerId:data.OwnerId,
        CreatedById:data.CreatedById,
        serviceType:data.serviceType
    },
    {
        title: data.title,
        description:data.title,
        code: data.code,
        sku: data.skuMg,
        talentTypeCategory:data.talentTypeCategory,
        OwnerId:data.OwnerId,
        CreatedById:data.CreatedById,
        serviceType:data.serviceType
    }];
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);

}