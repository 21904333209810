import React, { useCallback, useMemo, useRef, useState } from 'react';
import { PageHeader, Button, Badge, Drawer, Space, Spin, Modal, Tooltip, Tag } from 'antd';
import { ColDef, GridReadyEvent, ICellRendererParams, ValueFormatterParams, ValueGetterParams, } from 'ag-grid-community';
import { PlusOutlined, EditOutlined, CloseOutlined, StepBackwardOutlined, LeftOutlined, RightOutlined, StepForwardOutlined} from '@ant-design/icons';
import { dateFilterParams, dateFormate } from '../../../../utilities/DateUtility';
import { containsFilter } from '../../../../utilities/ActionUtility';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { getTalentTypeById,updateTalentType,getTalentTypeAllList, addTalentType} from '../../../../utilities/http-services/talent-type.service';
import { noRecordMsg } from '../../../../utilities/StaticDataUtility';
import JdQuestionCreate from '../jd-question-create/Jd-Question-Create';
import { addQuestion, getQuestionAllList, updateQuestion } from '../../../../utilities/http-services/caq-management.service';


const JdAnswerSequenceItemList: React.FC = () => {
    /**
     * formate date value
     * @param params data value
     * @returns formated value
     */
     const dateFormatter = (params: ValueFormatterParams) => {
        return dateFormate(params.value);
    }
    /**
    * Action Clone | Edit | Delete 
    * @param props Selected row object
    * @returns 
    */
    const actionCellRenderer = (props: ICellRendererParams) => {
        return (
            <Space>
                <Button block shape="circle" size="small" title='Edit' key="onEditBtnClicked" onClick={() => onSelectionChanged(props.data)}><EditOutlined /></Button>
            </Space>
        );
    };
     /* Selected Row Details */
     const onSelectionChanged = useCallback((selectedRows: any) => {
        showDrawer('edit');
        setSubLoading(true);
        getTalentTypeById(selectedRows._id).then((res: any) => {
            setselectedRow(res.data);
            setSelectedId(res.data._id);
        }).finally(() => {
            setSubLoading(false);
        });
    }, []);

    const titleCellRenderer = (params: ICellRendererParams) => {
        const value = params.value;
        return (<Tooltip placement="bottomLeft" title={value}>
            {value}
        </Tooltip>)
    }
    

    const addIconStyle: React.CSSProperties = { fontSize: "16px", cursor: "pointer" };

    /* AG Grid */
    const gridRef = useRef<AgGridReact<any>>(null);
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100vh - 180px)', width: '100%' }), []);
    const [gridApi, setGridApi] = useState<any>();
    const perPage = 50;
    const batchPerPage = 100;
    const [rowData, setRowData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [rowDataCount, setRowDataCount] = useState(0);
    const [pageSizeTo, setPageSizeTo] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [getPageSizeTo, setGetPageSizeTo] = useState(0);
    const [lastButtonDisabled, setLastButtonDisabled] = useState(false);
    const [firstButtonDisabled, setFirstButtonDisabled] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(0);
    const [allDataCount, setAllDataCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [actionType, setActionType] = useState('add');
    const [selectedRow, setselectedRow] = useState({});
    const [selectedId, setSelectedId] = useState('');    
    const [subLoading, setSubLoading] = useState(false);

    const [columnDefs] = useState<ColDef[]>([
        { field: 'Action', headerName: 'Action', filter: false, sortable: false, resizable: false, minWidth: 125, maxWidth: 125, cellRenderer: actionCellRenderer },
        { field: 'name', headerName: 'Question Name', minWidth: 200, filter: 'agTextColumnFilter' },
        { field: 'question', headerName: 'Question', minWidth: 200, cellRenderer: titleCellRenderer, filter: 'agTextColumnFilter' },
        { field: 'rich_text_question', headerName: 'Rich Text Question', minWidth: 200, filter: 'agTextColumnFilter'},
        { field: 'order', headerName: 'Order', minWidth: 200, filter: 'agTextColumnFilter'},
        { field: 'isTxt', headerName: 'Text', minWidth: 200, filter: 'agTextColumnFilter'},
        { field: 'createdAt', headerName: 'Created Date', minWidth: 200, filter: 'agDateColumnFilter', filterParams: dateFilterParams, valueFormatter: dateFormatter },
        { field: 'last_modified_date', headerName: 'Last Modified Date', minWidth: 200, filter: 'agDateColumnFilter', filterParams: dateFilterParams, valueFormatter: dateFormatter }
    ]);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            sortable: true,
            resizable: true,
            flex: 1,
            suppressMenu: true,
            minWidth: 100,
            maxWidth: 400,
            floatingFilter: true,
            editable: false,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            }
        };
    }, []);

    /**
     * On Grid Ready use call back
     */
    const onGridReady = useCallback((params: GridReadyEvent) => {
        setGridApi(params.api);
        fetchListing(batchPerPage, 1);
    }, []);

    const fetchListing = (perPage = 50, pageNo = 1, filter: any = {}) => {
        setLoading(true);
        let listData: any = [];
        getQuestionAllList(perPage, pageNo, filter).then((data: any) => {
            listData = data[0].data;
            prepairData(listData);
            listData = pageNo === 1 ? listData : [...rowData, ...listData];
            setRowData(listData);
            setPageNum(pageNo + 1);
            setRowDataCount(listData.length);
            setAllDataCount(data[1].count)
        }).finally(() => {
            setLoading(false);
        });
    }

    const prepairData = (data: any) => {
      data.forEach((element:any) => {
        element['categoryName'] = element.talentTypeCategory.title;
     });
    }
    /* Header Action */
    const showDrawer = (type:string) => {
        setActionType(type);
        setVisible(true);
    };

    /**
     * On details close
     */
    const onClose = () => {
        setVisible(false);
    };
    /**On create Clieck */
    const onCreateClose = () => {
        setVisible(false);
    };
    /**
     * On create Submit click
     * @param values create value
     */
     const onCreateFinish = async (values: any) => {
        onCreateClose();
        setLoading(true);
        if(selectedId && actionType==='edit'){
            const updateTalent = await updateQuestion(selectedId, values);
            setLoading(false);
            if(updateTalent){
                fetchListing(batchPerPage, 1);
            }
        }else{
            const addTalent = await addQuestion(values);
            setLoading(false);
            if(addTalent){
                fetchListing(batchPerPage, 1);
            }
        }
        
        
    }
    const headerActionBtn = () => {
        return [
            <Badge>
                <Button key="4" type="primary" onClick={() => showDrawer('add')}>Create New <PlusOutlined /></Button>
            </Badge>
        ]
    }
    
    const onPaginationChanged = () => {
        if (gridApi) {
            let currentPage = gridApi.paginationGetCurrentPage();
            let pageSize = 0;
            if (currentPage >= 1) {
                currentPage = 1 + (currentPage * gridApi.paginationGetPageSize());
                pageSize = pageLoaded + perPage;
                setPageLoaded(pageSize);
            } else {
                currentPage = 1;
                pageSize += gridApi.paginationGetPageSize();
                setPageLoaded(pageSize);
            }
            setGetPageSizeTo(currentPage);
            setPageSizeTo(pageSize);
            setCurrentPage(gridApi.paginationGetCurrentPage() + 1);
            setTotalPages(gridApi.paginationGetTotalPages());
            let currentPageVisited = gridApi.paginationProxy.currentPage;
            let lastPageToVisited = gridApi.paginationProxy.totalPages;

            if (currentPageVisited === 0) {
                setFirstButtonDisabled(true);
                setLastButtonDisabled(false);
            } else if (lastPageToVisited === (currentPageVisited + 1)) {
                setFirstButtonDisabled(false);
                setLastButtonDisabled(true);
            } else {
                setFirstButtonDisabled(false);
                setLastButtonDisabled(false);
            }
        }
    }
    
    const customPagination = () => {
        const loadMorePage = () => {
            fetchListing(batchPerPage, pageNum, {});
        };
        const onBtFirst = () => {
            gridApi.paginationGoToFirstPage();
        };

        const onBtLast = () => {
            gridApi.paginationGoToLastPage();
        };

        const onBtNext = () => {
            gridApi.paginationGoToNextPage();
        };

        const onBtPrevious = () => {
            gridApi.paginationGoToPreviousPage();
        };
        return [
            <div className="ag-paging-panel ag-custom-pagination">
                <span>Showing <b>{getPageSizeTo}</b> to <b>{(allDataCount > pageSizeTo) ? pageSizeTo : allDataCount}</b> of Total: <b>{allDataCount}</b></span>

                {/* <span>Showing <b>{rowDataCount}</b> of Total:<b>{allDataCount}</b></span> */}
                <div className="ag-custom-padding">
                    <Button disabled={firstButtonDisabled} size="small" title="To First" key="onBtPreviousClicked" onClick={() => onBtFirst()}><StepBackwardOutlined /></Button>
                    <Button disabled={firstButtonDisabled} size="small" title="To Previous" key="onBtFirstClicked" onClick={() => onBtPrevious()}><LeftOutlined /></Button>
                    <span>Page <b>{currentPage}</b> of <b>{totalPages}</b></span>
                    <Button disabled={lastButtonDisabled} size="small" title='To Next' key="onBtNextClicked" onClick={() => onBtNext()}><RightOutlined /></Button>
                    <Button disabled={lastButtonDisabled} size="small" title='To Last' key="onBtLastClicked" onClick={() => onBtLast()}><StepForwardOutlined /></Button>
                    <Button disabled={rowDataCount === allDataCount ? true : false} title='Load More' onClick={() => loadMorePage()}>Load More</Button>
                </div>
            </div>
        ]
    }
    const handleChange = () => {
        if(gridApi.getDisplayedRowCount() === 0) {
            gridApi.showNoRowsOverlay();
        }else{
            gridApi.hideOverlay();
        }
    };
    return (
        <div style={containerStyle} key="AgGridSample">
            <Spin spinning={loading} tip="Loading...">
                <PageHeader title="Answer Sequence Item List" extra={headerActionBtn()} />
                { actionType === 'add' && (
                <Drawer width={'50%'} title="Create Answer Sequence Item" maskClosable={false} closable={false} placement="right" onClose={onClose} visible={visible}
                    extra={<Button block shape="circle" type="text" onClick={onCreateClose} ><CloseOutlined /></Button>} destroyOnClose>
                    <Spin spinning={subLoading} tip="Loading...">
                        <JdQuestionCreate onFinish={onCreateFinish} />
                    </Spin>
                </Drawer> )}
                <div style={gridStyle} className="ag-theme-alpine" key="agthemealpine">
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        rowSelection={'single'}
                        paginationPageSize={perPage}
                        animateRows={true}
                        suppressMenuHide={true}
                        enableRangeSelection={true}
                        pagination={true}
                        suppressPaginationPanel={true}
                        onPaginationChanged={onPaginationChanged}
                        overlayNoRowsTemplate={noRecordMsg}
                        onFilterChanged={() => handleChange()}
                    ></AgGridReact>
                    {customPagination()}   
                    { actionType === 'edit' && (<Drawer title="Update Answer Sequence Item" maskClosable={false} closable={false} placement="right" onClose={onClose} visible={visible}
                        extra={<Button block shape="circle" type="text" onClick={onCreateClose} ><CloseOutlined /></Button>} destroyOnClose>
                        <Spin spinning={subLoading} tip="Loading...">
                            <JdQuestionCreate data={selectedRow} onFinish={onCreateFinish}/>
                        </Spin>
                    </Drawer> )}               
                </div>
            </Spin>
        </div>

    );
    
};

export default JdAnswerSequenceItemList;