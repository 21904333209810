import fetchIntercept from "fetch-intercept";
// const token = localStorage.getItem('accessToken');
const idToken = localStorage.getItem('idToken');
fetchIntercept.register({
    request: function(url, config) {
      // Modify the url or config here
      console.log("URL", url);
     if(config && config.headers) {
      config.headers['Authorization'] = `Bearer ${idToken}`
     } else if (config){
      config = {headers: {Authorization: `Bearer ${idToken}`}}
     } else {
      config = {headers: {Authorization: `Bearer ${idToken}`}}
     }
     if (url.indexOf('den') !== -1) {
      config.headers['Authorization'] = `Bearer ${idToken}`;
     }
    //  if(url.match('/api/v1/skill/list') || url.match('/api/v1/skill/count') || url.match('/api/v1/skill//cities')){
    //   delete config.headers['Authorization'];
    //  }
      return [url, config];
    },
  
    requestError: function(error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },
  
    response: function(response) {
      // Modify the reponse object
      return response;
    },
  
    responseError: function(error) {
      if(error && error.message){
        localStorage.setItem('apiStatusCode', '401');
     // window.location.reload();
      }
      // Handle an fetch error
      return Promise.reject(error);
    }
  });