import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  PageHeader,
  Button,
  Badge,
  Drawer,
  Space,
  Spin,
  Modal,
  Tooltip,
  Collapse,
  Card,
  Row,
  Col,
  Checkbox,
} from "antd";
import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import {
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
  StepBackwardOutlined,
  LeftOutlined,
  RightOutlined,
  StepForwardOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  dateFilterParams,
  dateFormate,
} from "../../../../utilities/DateUtility";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { noRecordMsg } from "../../../../utilities/StaticDataUtility";
import {
  addAnswers,
  getAnswersList,
  updateAnswers,
  getAnswersById,
  getQuestionById,
} from "../../../../utilities/http-services/caq-management.service";
import JdAnswerCreate from "../jd-answer-create/Jd-Answer-Create";
import { useNavigate, useParams } from "react-router-dom";

const JdAnswerList: React.FC = () => {
  const navigate = useNavigate();
  const queryParams = useParams();
  /**
   * formate date value
   * @param params data value
   * @returns formated value
   */
  const dateFormatter = (params: ValueFormatterParams) => {
    return dateFormate(params.value);
  };
  /**
   * Action Clone | Edit | Delete
   * @param props Selected row object
   * @returns
   */
  const actionCellRenderer = (props: ICellRendererParams) => {
    return (
      <>
        <Space>
          <Button
            block
            shape="circle"
            size="small"
            title="Edit"
            key="onEditBtnClicked"
            onClick={() => onSelectionChanged(props.data)}
          >
            <EditOutlined />
          </Button>
          <Button
            block
            shape="circle"
            size="small"
            title="Delete"
            danger
            key="onDeleteBtnClicked"
            onClick={() => onDeleteClicked(props.data)}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      </>
    );
  };
  /* Selected Row Details */
  const onSelectionChanged = useCallback((selectedRows: any) => {
    showDrawer("edit");
    setSubLoading(true);
    getAnswersById(selectedRows._id)
      .then((res: any) => {
        setselectedRow(res.data);
        setSelectedId(res.data._id);
      })
      .finally(() => {
        setSubLoading(false);
      });
  }, []);

  const titleCellRenderer = (params: ICellRendererParams) => {
    const value = params.value;
    return (
      <Tooltip placement="bottomLeft" title={value}>
        {value}
      </Tooltip>
    );
  };

  // const addIconStyle: React.CSSProperties = { fontSize: "16px", cursor: "pointer" };

  /* AG Grid */
  const gridRef = useRef<AgGridReact<any>>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(
    () => ({ height: "calc(100vh - 180px)", width: "100%" }),
    []
  );
  const [gridApi, setGridApi] = useState<any>();
  const perPage = 50;
  const batchPerPage = 100;
  const [rowData, setRowData] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [rowDataCount, setRowDataCount] = useState(0);
  const [pageSizeTo, setPageSizeTo] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [getPageSizeTo, setGetPageSizeTo] = useState(0);
  const [lastButtonDisabled, setLastButtonDisabled] = useState(false);
  const [firstButtonDisabled, setFirstButtonDisabled] = useState(true);
  const [pageLoaded, setPageLoaded] = useState(0);
  const [allDataCount, setAllDataCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [actionType, setActionType] = useState("add");
  const [selectedRow, setselectedRow] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [subLoading, setSubLoading] = useState(false);
  const [questionData, setQuestionData] = useState<any>(null);

  const [columnDefs] = useState<ColDef[]>([
    {
      field: "Action",
      headerName: "Action",
      filter: false,
      sortable: false,
      resizable: false,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer: actionCellRenderer,
    },
    {
      field: "name",
      headerName: "Answer Name",
      minWidth: 200,
      filter: "agTextColumnFilter",
    },
    {
      field: "answer",
      headerName: "Answer",
      minWidth: 200,
      cellRenderer: titleCellRenderer,
      filter: "agTextColumnFilter",
    },
    {
      field: "order",
      headerName: "Order",
      minWidth: 200,
      filter: "agTextColumnFilter",
    },
    {
      field: "score",
      headerName: "Score",
      minWidth: 200,
      filter: "agTextColumnFilter",
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      minWidth: 200,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: dateFormatter,
    },
    {
      field: "updatedAt",
      headerName: "Last Modified Date",
      minWidth: 200,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: dateFormatter,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      resizable: true,
      flex: 1,
      suppressMenu: true,
      minWidth: 100,
      maxWidth: 400,
      floatingFilter: true,
      editable: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    };
  }, []);

  /**
   * On Grid Ready use call back
   */
  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
    // fetchListing(batchPerPage, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    fetchListing(batchPerPage, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.id])

  const _getQuestionById = async (id: any) => {
    try {
      if (questionData && questionData?._id === id) return;

      const res = await getQuestionById(id);
      if (res?.data?._id) {
        setQuestionData(res.data);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const onCancelInfo = () => {
    navigate(-1);
    // fetchListing(batchPerPage, 1);
    setQuestionData(null);
  };

  const fetchListing = (perPage = 50, pageNo = 1, filter: any = {}) => {
    setLoading(true);
    let listData: any = [];
    if (queryParams?.id) {
      filter.question = queryParams?.id;
      _getQuestionById(queryParams?.id);
    }
    getAnswersList(perPage, pageNo, filter)
      .then((data: any) => {
        listData = data[0].data;
        prepairData(listData);
        listData = pageNo === 1 ? listData : [...rowData, ...listData];
        setRowData(listData);
        setPageNum(pageNo + 1);
        setRowDataCount(listData.length);
        setAllDataCount(data[1].count);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const prepairData = (data: any) => {
    //   data.forEach((element:any) => {
    //     element['categoryName'] = element.talentTypeCategory.title;
    //  });
  };
  /* Header Action */
  const showDrawer = (type: string) => {
    setActionType(type);
    setVisible(true);
  };

  /**
   * On details close
   */
  const onClose = () => {
    setVisible(false);
  };
  /**On create Clieck */
  const onCreateClose = () => {
    setVisible(false);
  };
  const onDeleteClicked = async (selectedRows: any) => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          setLoading(true);
          const updateQSet = await updateAnswers(selectedRows?._id, {
            is_deleted: true,
          });
          setLoading(false);
          if (updateQSet) {
            fetchListing(batchPerPage, 1);
          }
        } catch (error) {
          console.log({ error });
          setLoading(false);
        }
      },
      onCancel: () => {
        console.log("Delete action cancelled");
      },
    });
  };
  /**
   * On create Submit click
   * @param values create value
   */
  const onCreateFinish = async (values: any) => {
    onCreateClose();
    setLoading(true);
    if (selectedId && actionType === "edit") {
      const updateTalent = await updateAnswers(selectedId, values);
      setLoading(false);
      if (updateTalent) {
        fetchListing(batchPerPage, 1);
      }
    } else {
      const addTalent = await addAnswers(values);
      setLoading(false);
      if (addTalent) {
        fetchListing(batchPerPage, 1);
      }
    }
  };
  const headerActionBtn = () => {
    return [
      <Badge>
        <Button key="4" type="primary" onClick={() => showDrawer("add")}>
          Create New <PlusOutlined />
        </Button>
      </Badge>,
    ];
  };

  const onPaginationChanged = () => {
    if (gridApi) {
      let currentPage = gridApi.paginationGetCurrentPage();
      let pageSize = 0;
      if (currentPage >= 1) {
        currentPage = 1 + currentPage * gridApi.paginationGetPageSize();
        pageSize = pageLoaded + perPage;
        setPageLoaded(pageSize);
      } else {
        currentPage = 1;
        pageSize += gridApi.paginationGetPageSize();
        setPageLoaded(pageSize);
      }
      setGetPageSizeTo(currentPage);
      setPageSizeTo(pageSize);
      setCurrentPage(gridApi.paginationGetCurrentPage() + 1);
      setTotalPages(gridApi.paginationGetTotalPages());
      let currentPageVisited = gridApi.paginationProxy.currentPage;
      let lastPageToVisited = gridApi.paginationProxy.totalPages;

      if (currentPageVisited === 0) {
        setFirstButtonDisabled(true);
        setLastButtonDisabled(false);
      } else if (lastPageToVisited === currentPageVisited + 1) {
        setFirstButtonDisabled(false);
        setLastButtonDisabled(true);
      } else {
        setFirstButtonDisabled(false);
        setLastButtonDisabled(false);
      }
    }
  };

  const customPagination = () => {
    const loadMorePage = () => {
      fetchListing(batchPerPage, pageNum, {});
    };
    const onBtFirst = () => {
      gridApi.paginationGoToFirstPage();
    };

    const onBtLast = () => {
      gridApi.paginationGoToLastPage();
    };

    const onBtNext = () => {
      gridApi.paginationGoToNextPage();
    };

    const onBtPrevious = () => {
      gridApi.paginationGoToPreviousPage();
    };
    return [
      <div className="ag-paging-panel ag-custom-pagination">
        <span>
          Showing <b>{getPageSizeTo}</b> to{" "}
          <b>{allDataCount > pageSizeTo ? pageSizeTo : allDataCount}</b> of
          Total: <b>{allDataCount}</b>
        </span>

        {/* <span>Showing <b>{rowDataCount}</b> of Total:<b>{allDataCount}</b></span> */}
        <div className="ag-custom-padding">
          <Button
            disabled={firstButtonDisabled}
            size="small"
            title="To First"
            key="onBtPreviousClicked"
            onClick={() => onBtFirst()}
          >
            <StepBackwardOutlined />
          </Button>
          <Button
            disabled={firstButtonDisabled}
            size="small"
            title="To Previous"
            key="onBtFirstClicked"
            onClick={() => onBtPrevious()}
          >
            <LeftOutlined />
          </Button>
          <span>
            Page <b>{currentPage}</b> of <b>{totalPages}</b>
          </span>
          <Button
            disabled={lastButtonDisabled}
            size="small"
            title="To Next"
            key="onBtNextClicked"
            onClick={() => onBtNext()}
          >
            <RightOutlined />
          </Button>
          <Button
            disabled={lastButtonDisabled}
            size="small"
            title="To Last"
            key="onBtLastClicked"
            onClick={() => onBtLast()}
          >
            <StepForwardOutlined />
          </Button>
          <Button
            disabled={rowDataCount === allDataCount ? true : false}
            title="Load More"
            onClick={() => loadMorePage()}
          >
            Load More
          </Button>
        </div>
      </div>,
    ];
  };
  const handleChange = () => {
    if (gridApi.getDisplayedRowCount() === 0) {
      gridApi.showNoRowsOverlay();
    } else {
      gridApi.hideOverlay();
    }
  };
  return (
    <div style={containerStyle} key="AgGridSample">
      <Spin spinning={loading} tip="Loading...">
        <PageHeader
          title="Answer List"
          extra={headerActionBtn()}
          onBack={() => navigate(-1)}
        />
        {actionType === "add" && (
          <Drawer
            width={"50%"}
            title="Create Answer"
            maskClosable={false}
            closable={false}
            placement="right"
            onClose={onClose}
            visible={visible}
            extra={
              <Button block shape="circle" type="text" onClick={onCreateClose}>
                <CloseOutlined />
              </Button>
            }
            destroyOnClose
          >
            <Spin spinning={subLoading} tip="Loading...">
              <JdAnswerCreate
                onFinish={onCreateFinish}
                questionData={questionData}
                onClose={onClose}
                rowData={rowData}
              />
            </Spin>
          </Drawer>
        )}

        {questionData?._id && (
          <>
            {/* Collapse component to show/hide details */}
            <Collapse defaultActiveKey={["1"]} style={{ marginBottom: 10 }}>
              <Collapse.Panel header="Information" key="1">
                <Card
                  title=""
                  bordered={false}
                  style={{ width: "100%" }}
                  className="question-info-card"
                >
                  <Row gutter={[16, 16]}>
                    {/* Information rows */}
                    <Col span={24} md={12}>
                      <p>
                        <strong>Question Name:</strong> {questionData?.name}
                      </p>
                    </Col>
                    <Col span={24} md={12}>
                      <p>
                        <strong>Order:</strong> {questionData?.order || "N/A"}
                      </p>
                    </Col>
                    <Col span={24} md={12}>
                      <p>
                        <strong>Question Size:</strong>{" "}
                        {questionData?.question_size}
                      </p>
                    </Col>
                    <Col
                      span={24}
                      md={12}
                      style={{ display: "flex", gap: 15, flexWrap: "wrap" }}
                    >
                      <p>
                        <strong>Optional:</strong>{" "}
                        <Checkbox checked={questionData?.optional} />
                      </p>
                      <p>
                        <strong>Text:</strong>{" "}
                        <Checkbox checked={questionData?.is_txt} />
                      </p>
                      <p>
                        <strong>Multi Answers:</strong>{" "}
                        <Checkbox checked={questionData?.is_multi} />
                      </p>
                    </Col>
                    <Col span={24} md={12}>
                      <p>
                        <strong>Question:</strong> {questionData?.question}
                      </p>
                    </Col>
                  </Row>
                  {/* Action Buttons */}
                  <div style={{ marginTop: 16, textAlign: "center" }}>
                    <Button onClick={() => onCancelInfo()}>Cancel</Button>
                  </div>
                </Card>
              </Collapse.Panel>
            </Collapse>
          </>
        )}

        <div style={gridStyle} className="ag-theme-alpine" key="agthemealpine">
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            rowSelection={"single"}
            paginationPageSize={perPage}
            animateRows={true}
            suppressMenuHide={true}
            enableRangeSelection={true}
            pagination={true}
            suppressPaginationPanel={true}
            onPaginationChanged={onPaginationChanged}
            overlayNoRowsTemplate={noRecordMsg}
            onFilterChanged={() => handleChange()}
          ></AgGridReact>
          {customPagination()}
          {actionType === "edit" && (
            <Drawer
              width={"50%"}
              title="Update Answer"
              maskClosable={false}
              closable={false}
              placement="right"
              onClose={onClose}
              visible={visible}
              extra={
                <Button
                  block
                  shape="circle"
                  type="text"
                  onClick={onCreateClose}
                >
                  <CloseOutlined />
                </Button>
              }
              destroyOnClose
            >
              <Spin spinning={subLoading} tip="Loading...">
                <JdAnswerCreate
                  data={selectedRow}
                  onFinish={onCreateFinish}
                  questionData={questionData}
                  onClose={onClose}
                  rowData={rowData}
                />
              </Spin>
            </Drawer>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default JdAnswerList;
