import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Select, Space, Input, Radio } from "antd";
import "./Jd-Question-Create.css";
import { questionSizeList } from "../../../../utilities/StaticDataUtility";
import { getQuestionSetList } from "../../../../utilities/http-services/caq-management.service";
import MDEditor from "@uiw/react-md-editor";

export interface IProps {
  data?: any;
  onClose?: () => void;
  onFinish?: any;
  onClear?: any;
  disabledForm?: boolean;
  fromPage?: any;
  questionSetData?: any;
  rowData?: any;
}
const JdQuestionCreate: React.FC<IProps> = (pageProps) => {
  const [form] = Form.useForm();
  const [questionSize, setQuestionSize] = useState(
    form.getFieldValue("question_size") || "Small (255 or less)"
  );
  const [questionType, setQuestionType] = useState(
    form.getFieldValue("question_type") || "optional"
  );
  // const disabledItem = pageProps.disabledForm ? true : false;
  const [questionSetOptions, setQuestionSetOptions] = useState([]);
  const onFinish = (values: any) => {
    pageProps.onFinish(values);
  };
  // const onClear = () => {
  //   //reset form
  //   form.resetFields();
  //   if(pageProps.onClear) pageProps.onClear();
  //   if(pageProps?.questionSetData?._id) {
  //     form.setFieldsValue({ question_set: pageProps?.questionSetData?._id });
  //     // setQuestionSize('Small (255 or less)')
  //     // setQuestionType('text')
  //   }
  // };
  // const handleChange = (value: string[]) => {
  //   console.log(`selected ${value}`);
  // };
  useEffect(() => {
    getQuestionSetList().then((res: any) => {
      console.log(res);
      setQuestionSetOptions(res);
    });
  }, []);
  useEffect(() => {
    if (pageProps.data && Object.keys(pageProps.data)) {
      setFormValue(pageProps.data);
    } else if (pageProps?.questionSetData?._id) {
      form.setFieldsValue({ question_set: pageProps?.questionSetData?._id });
      form.setFieldsValue({ order: pageProps?.rowData?.length + 1 || 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageProps?.data, pageProps?.questionSetData?._id]);
  useEffect(() => {
    form.setFieldsValue({ question_size: questionSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionSize]);
  useEffect(() => {
    form.setFieldsValue({ question_type: questionType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionType]);
  const setFormValue = (data: any) => {
    form.setFieldsValue({ name: data.name });
    form.setFieldsValue({ question_set: data.question_set });
    form.setFieldsValue({
      order: data.order || pageProps?.rowData?.length + 1 || 1,
    });
    form.setFieldsValue({
      question_size: data.question_size || "Small (255 or less)",
    });
    let question_type = "";
    if (data.optional) question_type = "optional";
    if (data.is_txt) question_type = "text";
    if (data.is_multi) question_type = "multi";
    form.setFieldsValue({ optional: data.optional });
    form.setFieldsValue({ is_txt: data.is_txt });
    form.setFieldsValue({ is_multi: data.is_multi });
    form.setFieldsValue({ question_type: question_type });
    if (data.question_size === "Rich Text (up to 32k)") {
      form.setFieldsValue({ rich_text_question: data.rich_text_question });
    } else {
      form.setFieldsValue({ question: data.question });
    }
  };

  const pageWithFrom = () => {
    return (
      <>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          hideRequiredMark
          autoComplete="off"
        >
          {pageWithoutForm()}
          <Row>
            <Col span={24}>
              <Space>
                <Button onClick={pageProps.onClose}>Cancel</Button>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </>
    );
  };
  const pageWithoutForm = () => {
    return (
      <>
        <Row gutter={16}>
          <Col xs={24} md={pageProps.fromPage === "addQuest" ? 12 : 24}>
            <Form.Item
              label="Question Name"
              name="name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Question Name is required",
                  max: 255,
                },
              ]}
            >
              <Input showCount maxLength={255} />
            </Form.Item>
          </Col>
          <Col xs={24} md={pageProps.fromPage === "addQuest" ? 12 : 24}>
            <Form.Item
              label="Question Set"
              name="question_set"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Question Set is required",
                },
              ]}
            >
              <Select
                placeholder="--None--"
                options={questionSetOptions}
                disabled={pageProps?.questionSetData?._id ? true : false}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={pageProps.fromPage === "addQuest" ? 12 : 24}>
            <Form.Item
              label="Order"
              name="order"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  validator: (_, value) => {
                    if (value && (isNaN(value) || value <= 0)) {
                      return Promise.reject("Order must be greater than 0");
                    }
                    if (String(value).includes(".")) {
                      return Promise.reject("Please enter a natural number.");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input maxLength={4} />
            </Form.Item>
          </Col>
          <Col xs={24} md={pageProps.fromPage === "addQuest" ? 12 : 24}>
            <Form.Item
              name="question_type"
              label="Choose an option"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "*Required",
                },
              ]}
            >
              <Radio.Group onChange={(e) => setQuestionType(e.target.value)}>
                <Radio value="optional">Optional</Radio>
                <Radio value="text">Text</Radio>
                <Radio value="multi">Multi Answers</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {/* {questionType === "text" ? ( */}
          <>
            <Col xs={24} md={pageProps.fromPage === "addQuest" ? 12 : 24}>
              <Form.Item
                label="Question Size"
                name="question_size"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Question Size is required",
                    max: 255,
                  },
                ]}
              >
                <Select
                  placeholder="--None--"
                  options={questionSizeList}
                  onChange={(value) => setQuestionSize(value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={pageProps.fromPage === "addQuest" ? 12 : 24}>
              {questionSize === "Rich Text (up to 32k)" ? (
                <Form.Item
                  label="Question"
                  name="rich_text_question"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Question is required",
                    },
                  ]}
                >
                  <MDEditor
                    preview="edit"
                    className="react-markdown-description"
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Question"
                  name="question"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Question is required",
                      max: 255,
                    },
                  ]}
                >
                  <Input.TextArea showCount maxLength={255} />
                </Form.Item>
              )}
            </Col>
          </>
          {/* ) : null} */}
        </Row>
      </>
    );
  };

  return (
    <div className="add-quest-content">
      {pageProps.fromPage === "addQuest" ? pageWithoutForm() : pageWithFrom()}
    </div>
  );
};

export default JdQuestionCreate;
