import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Steps,
  Button,
  Form,
  Space,
  Typography,
  Spin,
  Divider,
} from "antd";
import { stepQuestions } from "../../../../utilities/StaticDataUtility";
import { CustomeNotification } from "../../../../utilities/NotificationUtility";
import {
  addAnswers,
  addQuestion,
  addQuestionSet,
  getQuestionSetAllList,
  getQuestionSetById,
  updateAnswers,
  updateQuestion,
  updateQuestionSet,
} from "../../../../utilities/http-services/caq-management.service";

import JdQuestionSetCreate from "../jd-question-set-create/Jd-Question-Set-Create";
import JdQuestionCreate from "../jd-question-create/Jd-Question-Create";
import JdAnswerCreate from "../jd-answer-create/Jd-Answer-Create";
import "./Jd-Add-Question-Set.css";
import { useNavigate } from "react-router-dom";
const { Step } = Steps;
const { Title } = Typography;
export interface IProps {
  data?: any;
  onClose?: () => void;
  onFinish?: any;
  onClear?: any;
}
const JdAddQuestionSet: React.FC<IProps> = (pageProps) => {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [formTitle, setFormTitle] = useState("Add Question set"); 
  const navigate = useNavigate();
  const initialFormValues = {};
  /**
   * On form submit every steps
   * @param values selected step form value
   */
  const onFormFinish = (values: any) => {
    console.log("Success:", values);
    window.scrollTo(0, 0 - 15);
    const data = { ...formData, ...values };
    setFormData(data);
    onSaveAndContinue(data, values);
  };

  const [questionSetId, setQuestionSetId] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [answerId, setAnswerId] = useState("");

  const onSaveAndContinue = async (data: any, formValue: any) => {
    try {
      setLoading(true);
      console.log("onSaveAndContinue::", current);

      let res;

      if (current === 0) {
        if (questionSetId) {
          res = await updateQuestionSet(questionSetId, data);
        } else {
          res = await addQuestionSet(data);
          setQuestionSetId(res?._id);
        }

        if (!res) {
          CustomeNotification("error", `Bad Request`, "Please try again...");
          return;
        }

        setCurrent(current + 1);
        setFormTitle("Add Question");
      } else if (current === 1) {
        if (questionId) {
          res = await updateQuestion(questionId, data);
        } else {
          res = await addQuestion(data);
          setQuestionId(res?._id);
        }
        
        if (!res) {
          CustomeNotification("error", `Bad Request`, "Please try again...");
          return;
        }

        setCurrent(current + 1);
        setFormTitle("Add Answer");
      } else if (current === 2) {
        if (answerId) {
          res = await updateAnswers(answerId, data);
        } else {
          res = await addAnswers(data);
          setAnswerId(res?._id);
        }

        if (!res) {
          CustomeNotification("error", `Bad Request`, "Please try again...");
          return;
        }

        onCancel();
      } else {
        CustomeNotification("error", `Bad Request`, "Please try again...");
        setCurrent(0)
      }
    } catch (error) {
      console.log({ error });
      CustomeNotification("error", `Bad Request`, "Please try again...");
    } finally {
      setLoading(false);
    }
  }; 

  useEffect(() => {
    if (pageProps.data && Object.keys(pageProps.data)) {
      setFormValue(pageProps.data);
    }
  }, [pageProps.data]);

  const setFormValue = (data: any) => {
    form.setFieldsValue({ business_unit: data.business_unit });
    form.setFieldsValue({ name: data.name });
    form.setFieldsValue({ language: data.language });
    form.setFieldsValue({ passing_score: data.passing_score });
    form.setFieldsValue({ skill_codes: data.skill_codes });
    form.setFieldsValue({ status: data.status });
  };

  /**
   * Step content
   * @returns Step Component
   */
  const stepPageContent = () => {
    switch (current) {
      case 0:
        return (
          <div>
            <JdQuestionSetCreate data={formData} fromPage="addQuest" />
          </div>
        );
      case 1:
        return (
          <div>
            <JdQuestionCreate data={formData} fromPage="addQuest" />
          </div>
        );
      case 2:
        return (
          <div>
            <JdAnswerCreate fromPage="addQuest" />
          </div>
        );
      default:
        return (
          <div>
            <JdQuestionSetCreate />
          </div>
        );
    }
  };

  /* eslint-enable no-template-curly-in-string */
  const actionFooterBtnContent = () => {
    return (
      <Space>
        <Button
          type="primary"
          onClick={() => setLoading(true)}
          htmlType="submit"
        >
          {current === stepQuestions.length - 1
            ? "SUBMIT"
            : "SAVE AND CONTINUE"}
        </Button>
        {/* {current === stepQuestions.length - 1 && (
                <Button type="primary" onClick={() => onPublish()}>
                    SUBMIT
                </Button>
            )} */}
        {current > 0 && current < stepQuestions.length - 1 && (
          <Button style={{ margin: "0 8px" }} onClick={() => onPrev()}>
            BACK
          </Button>
        )}
        <Button style={{ margin: "0 8px" }} onClick={() => onCancel()}>
          CANCEL
        </Button>
      </Space>
    );
  };
  /**
   * on Back btn
   */
  const onPrev = () => {
    if (current === 0) {
      onCancel();
      return;
    }
    setCurrent(current - 1);
  };

  /**
   * On Edit Btn
   */
  const onEdit = () => {
    setCurrent(0);
  };

  /**
   * On form submit failed
   * @param errorInfo
   */
  const onFinishFailed = (errorInfo: any) => {
    setLoading(false);
    console.log("Failed:", errorInfo);
  };

  /**
   *
   * @param value Form Value change
   */
  const onValuesChange = async (value: any) => {};

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  /**
   * On Cancel
   */
  const onCancel = () => {
    navigate(`/question-sets`);
  };
  /**
   * Final Summary page
   */
  const onPublish = async () => {
    // setLoading(true);
    // const jobDescriptionId = await updateJDDescription(jdCatalogId, { "publishedStatus": "PUBLISHED","isLatestJD":true }, current);
    // CustomeNotification('success', `Job Description Created Successfully.`, 'Job Description Created will be immediately added in list shortly.');
    // setLoading(false);
    // setTimeout(() => {
    //     navigate(`/question-sets`);
    // }, 200)
  };
  return (
    <Spin spinning={loading} tip="Loading...">
      <Form
        layout="vertical"
        initialValues={initialFormValues}
        autoComplete="off"
        onFinish={onFormFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        form={form}
        validateMessages={validateMessages}
        wrapperCol={{ span: 10 }}
        labelCol={{ span: 16 }}
      >
        <div key={1}>
          <PageHeader onBack={() => onPrev()} title={formTitle} />
          <Steps current={current}>
            {stepQuestions.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">
            <Title level={4}>{stepQuestions[current].title}</Title>
            {stepPageContent()}
            <Divider />
            {actionFooterBtnContent()}
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default JdAddQuestionSet;
