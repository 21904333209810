import environment from '../../environments/environment';
import "./fetch-intercept.service";
const baseUrl = environment.baseUrl + '/api/v1';

export const getQuestionSetList = (pageSize: number = 100, paneNo: Number = 0): Promise<any> => {
    const aprUrl = `${baseUrl}/jd/quetionsSets?pageSize=${pageSize}&page=${paneNo}`;
    return fetch(aprUrl)
        .then((resp) => resp.json())
        .then(body =>
            body.data.map(
                (item: any) => ({ key: item.sfdc_id ? item.sfdc_id : item._id, label: item.name, value: item._id}),
            )
        );
}

export const getQuestionList = (pageSize: number = 100, paneNo: Number = 0): Promise<any> => {
    const aprUrl = `${baseUrl}/jd/quetions?pageSize=${pageSize}&page=${paneNo}`;
    return fetch(aprUrl)
        .then((resp) => resp.json())
        .then(body =>
            body.data.map(
                (item: any) => ({ key: item._id, label: item.name, value: item._id}),
            )
        );
}
export const getQuestionSetById = (tId:any): Promise<any> => {
    const apiUrl = `${baseUrl}/jd/getQuestionSetById/${tId}/detail`;
    return fetch(apiUrl)
        .then((resp) => resp.json())
        .then((data) => {
            return data;
        });
}
export const updateQuestionSet = (tId: string, data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/jd/updateQuestionSet/` + tId;
    let postData: any = data;
    if (data["business_unit"]) {
        try {
          postData["business_unit"] = data["business_unit"].join(";");
        } catch (error) {}
      }
      if (data["skill_codes"]) {
        try {
          postData["skill_codes"] = data["skill_codes"].join(";");
        } catch (error) {}
      }
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);

}
export const getQuestionSetAllList = (pageSize: number = 50, paneNo: Number = 0,filter={}): Promise<unknown> => {
    const listUrl = `${baseUrl}/jd/questionSetList?pageSize=${pageSize}&page=${paneNo}`;
    const countTalentUrl = `${baseUrl}/jd/allQuestionSetCount`;
    const requestOptions:any = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    
    if(filter!==undefined && Object.keys(filter) && Object.keys(filter).length){
       requestOptions['body'] = JSON.stringify(filter);
    }
    const talentApiUrl: any = [fetch(listUrl,requestOptions), fetch(countTalentUrl,requestOptions) ];  

    return Promise.all(talentApiUrl).then(function (responses) {
        return Promise.all(responses.map(function (response) { return response.json() }));
    }).then(function (promiseData) {
        return promiseData;
    })

}

export const addQuestionSet = (data: any): Promise<any> => {
    console.log({data})
    const apiUrl = `${baseUrl}/jd/createQuestionSet`;
    let postData: any = data;
    if (data["business_unit"]) {
      try {
        postData["business_unit"] = data["business_unit"].join(";");
      } catch (error) {}
    }
    if (data["skill_codes"]) {
      try {
        postData["skill_codes"] = data["skill_codes"].join(";");
      } catch (error) {}
    }
    postData['language'] = postData.language || 'English';
    postData['status'] = postData.status || 'Active';
    postData['is_deleted'] = false;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);

}

export const getQuestionAllList = (pageSize: number = 50, paneNo: Number = 0,filter={}): Promise<unknown> => {
    const listUrl = `${baseUrl}/jd/questionList?pageSize=${pageSize}&page=${paneNo}`;
    const countTalentUrl = `${baseUrl}/jd/allQuestionCount`;
    const requestOptions:any = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    
    if(filter!==undefined && Object.keys(filter) && Object.keys(filter).length){
       requestOptions['body'] = JSON.stringify(filter);
    }
    const talentApiUrl: any = [fetch(listUrl,requestOptions), fetch(countTalentUrl,requestOptions) ];  

    return Promise.all(talentApiUrl).then(function (responses) {
        return Promise.all(responses.map(function (response) { return response.json() }));
    }).then(function (promiseData) {
        return promiseData;
    })

}

export const updateQuestion = (tId: string, data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/jd/updateQuestion/` + tId;
    let postData: any = data;

    if(data.question_type) {
        postData['optional'] = data.question_type === 'optional' ? true : false;
        postData['is_txt'] = data.question_type === 'text' ? true : false;
        postData['is_multi'] = data.question_type === 'multi' ? true : false;
    }
    if(data.question_size === 'Rich Text (up to 32k)') {
        postData['rich_text_question'] = data.rich_text_question
        postData['question'] = ''
    } else {
        postData['rich_text_question'] = ''
        postData['question'] = data.question
    }

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data._id);

}

export const addQuestion = (data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/jd/createQuestion`;
    console.log(data)
    let postData: any = data;
    
    postData['question_line'] = 'aAA4z000000RQ0HGAW'; //Default (Do not use/delete)
    
    if(data.question_type) {
        postData['optional'] = data.question_type === 'optional' ? true : false;
        postData['is_txt'] = data.question_type === 'text' ? true : false;
        postData['is_multi'] = data.question_type === 'multi' ? true : false;
    }
    if(data.question_size === 'Rich Text (up to 32k)') {
        postData['rich_text_question'] = data.rich_text_question
        postData['question'] = ''
    } else {
        postData['rich_text_question'] = ''
        postData['question'] = data.question
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);

}

export const getQuestionById = (tId:any): Promise<any> => {
    const apiUrl = `${baseUrl}/jd/getQuestionById/${tId}/detail`;
    return fetch(apiUrl)
        .then((resp) => resp.json())
        .then((data) => {
            return data;
        });
}

export const getAnswersList = (pageSize: number = 50, paneNo: Number = 0,filter={}): Promise<unknown> => {
    const listUrl = `${baseUrl}/jd/allAnswerList?pageSize=${pageSize}&page=${paneNo}`;
    const countTalentUrl = `${baseUrl}/jd/allAnswerCount`;
    const requestOptions:any = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    
    if(filter!==undefined && Object.keys(filter) && Object.keys(filter).length){
       requestOptions['body'] = JSON.stringify(filter);
    }
    const talentApiUrl: any = [fetch(listUrl,requestOptions), fetch(countTalentUrl,requestOptions) ];  

    return Promise.all(talentApiUrl).then(function (responses) {
        return Promise.all(responses.map(function (response) { return response.json() }));
    }).then(function (promiseData) {
        return promiseData;
    })

}

export const updateAnswers = (tId: string, data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/jd/updateAnswer/` + tId;
    let postData: any = data;
    if(postData?.answers?.length) {
        postData = {
            ...data,
            ...postData?.answers?.[0]
        }
    }
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data._id);

}

export const addAnswers = (data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/jd/createAnswer`;    
    let postData: any = [];
    data?.answers?.forEach((val:any) => {
        console.log(val)
        val['question'] = data['question']
        postData.push(val);
    });
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);

}

export const getAnswersById = (tId:any): Promise<any> => {
    const apiUrl = `${baseUrl}/jd/getAnswersById/${tId}/detail`;
    return fetch(apiUrl)
        .then((resp) => resp.json())
        .then((data) => {
            return data;
        });
}