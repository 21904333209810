import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Space,
  SelectProps,
  Input,
  Spin,
  PageHeader,
  Divider,
  Modal,
} from "antd";
import {
  langaugeList,
  statusList,
} from "../../../../utilities/StaticDataUtility";
import "./Jd-Question-Set-Create.css";
import { useNavigate } from "react-router-dom";
import { extractQueryParams } from "../../../../helpers";
import {
  addQuestionSet,
  getQuestionSetById,
  updateQuestionSet,
} from "../../../../utilities/http-services/caq-management.service";
import { CustomeNotification } from "../../../../utilities/NotificationUtility";

export interface IProps {
  data?: any;
  onClose?: () => void;
  onFinish?: any;
  onClear?: any;
  fromPage?: any;
}
const JdQuestionSetCreate: React.FC<IProps> = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const disabledItem = false;
  const onSaveAndContinue = async (formValue: any) => {
    try {
      setLoading(true);

      let res: any;

      if (data?._id) {
        res = await updateQuestionSet(data?._id, formValue);
      } else {
        res = await addQuestionSet(formValue);
      }

      if (!res) {
        CustomeNotification("error", `Bad Request`, "Please try again...");
        return;
      }
      
      if (data?._id) {
        navigate(-1)
      } else {
        Modal.confirm({
          title: 'Continue Creating Questions?',
          content: 'Do you want to continue adding questions to this set?',
          onOk() {
            console.log({res})
            navigate(`/questions/${res?._id}`)
          },
          onCancel() {
            navigate(-1)
          },
        });
      }
    } catch (error) {
      console.log({ error });
      CustomeNotification("error", `Bad Request`, "Please try again...");
    } finally {
      setLoading(false);
    }
  };
  const onFinish = (values: any) => {
    // pageProps.onFinish(values);
    onSaveAndContinue(values);
  };
  const onClear = () => {
    //reset form
    form.resetFields();
    // pageProps.onClear();
    navigate(-1);
  };
  const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
  };
  const businessUnitOptions: SelectProps["options"] = [
    {
      label: "Information Technology",
      value: "Information Technology",
    },
    {
      label: "Finance and Accounting",
      value: "Finance and Accounting",
    },
    {
      label: "Commercial",
      value: "Commercial",
    },
  ];

  const skillCodeOptions: SelectProps["options"] = [
    {
      label: "J2EE",
      value: "J2EE",
    },
    {
      label: ".NET",
      value: ".NET",
    },
    {
      label: "PHP",
      value: "PHP",
    },
  ];

  const _getQuestionSetById = async (id: any) => {
    try {
      setLoading(true);
      const res = await getQuestionSetById(id);
      if (res?.data?._id) {
        setData(res.data);
        setFormValue(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { id } = extractQueryParams();

    if (id) {
      _getQuestionSetById(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFormValue = (data: any) => {
    form.setFieldsValue({
      business_unit: data.business_unit ? data.business_unit.split(";") : [],
    });
    form.setFieldsValue({ name: data.name });
    form.setFieldsValue({ language: data.language || "English" });
    form.setFieldsValue({ passing_score: data.passing_score || "" });
    form.setFieldsValue({
      skill_codes: data.skill_codes ? data.skill_codes.split(";") : [],
    });
    form.setFieldsValue({ status: data.status || "Active" });
  };

  const pageWithoutForm = () => {
    return (
      <>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Question Set Name"
              name="name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Question set name is required",
                },
              ]}
            >
              <Input showCount />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Business Unit"
              name="business_unit"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                defaultValue={[]}
                onChange={handleChange}
                options={businessUnitOptions}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Language"
              name="language"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                disabled={disabledItem}
                defaultValue="English"
                placeholder="Select Language"
                options={langaugeList}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Passing Score"
              name="passing_score"
              rules={[
                // {
                //   required: true,
                //   message: "Passing Score is required",
                // },
                {
                  validator: (_, value) => {
                    if (value && (isNaN(value) || value <= 0)) {
                      return Promise.reject(
                        "Passing Score must be greater than 0"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input showCount maxLength={3} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Skill Codes"
              name="skill_codes"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                defaultValue={[]}
                onChange={handleChange}
                options={skillCodeOptions}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Status"
              name="status"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                disabled={disabledItem}
                defaultValue="Active"
                placeholder="Select Status"
                options={statusList}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <div style={{ width: "100%", height: "100%" }} key="AgGridSample">
      <Spin spinning={loading} tip="Loading...">
        <div className="add-qset-content">
          <PageHeader
            title={`${data?._id ? "Update" : "Add"} Question Set`}
            onBack={() => navigate(-1)}
          />
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            autoComplete="off"
          >
            {pageWithoutForm()}
            <Divider />
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <Space>
                  <Button onClick={onClear}>Cancel</Button>
                  <Button type="primary" htmlType="submit">
                    {data?._id ? "Update" : "Add"}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default JdQuestionSetCreate;
